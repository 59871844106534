<template>
  <v-menu offset-y open-on-hover>
    <template v-slot:activator="{ on, attrs }">
      <v-btn class="mr-2" color="primary" outlined v-bind="attrs" v-on="on">
        <img width="18" height="18" :src="require('@/assets/icons/airflow.png')" alt="airflow" />
      </v-btn>
    </template>
    <v-list>
      <v-list-item @click="$emit('clickOnInsert')">
        <v-list-item-content>Inserção</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('clickOnEdit')">
        <v-list-item-content>Edição</v-list-item-content>
      </v-list-item>
      <v-list-item @click="$emit('clickOnDelete')">
        <v-list-item-content>Deleção</v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  name: 'DagMenu'
}
</script>
